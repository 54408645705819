html,
body {
  min-height: 100%;
}

#__next {
  height: 100%;
}

@media (max-width: 600px) {
  .MuiTypography-h4 {
    font-size: 1.5rem; /* Smaller font size for h4 elements on small screens */
  }
  .MuiTypography-h5 {
    font-size: 1.25rem; /* Smaller font size for h5 elements on small screens */
  }
}

code {
  font-family: 'Inter', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  padding: 0.1rem 0.4rem;
  font-size: 90%;
  color: #d400ff;
  border-radius: 0.1335rem;
}
code:not([class*='language-']):before,
code:not([class*='language-']):after {
  content: '`';
}
code[class*='language-'] {
  padding: 0;
}
